import React, { useState, useEffect, useCallback, FC, memo } from 'react';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';
import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { BrandedSearchPageCollectionProps } from '@vakantiesnl/components/src/__LEGACY__/molecules/BrandedSearchPageCollection';
import { UspExpandedCollection } from '@vakantiesnl/components/src/__LEGACY__/molecules/UspExpandedCollection';
import { checkCache, storeInCache } from '@vakantiesnl/components/src/utils/redis';
import { getEntryCollection } from '@vakantiesnl/services/src/contentful';
import { mapHomepageTemplate } from '@vakantiesnl/services/src/contentful/mapper';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { RecommendationsProvider } from '@vakantiesnl/services/src/context/useRecommendationsContext/useRecommendationsContext';
import { errorHandler } from '@vakantiesnl/services/src/util/errorHandling';
import { checkHomepageRedirect } from '@vakantiesnl/services/src/util/homePageRedirects';
import { RawTemplateHomepage, TemplateHomepage } from '@vakantiesnl/types';
import dynamic from 'next/dynamic';

import { GlobalPageProps, VaknlNextPage } from './_app';
import { NextPreviewPageContext } from '../../@types/next-preview-page-context';
import QuickSearch from '../blocks/quick-search';
import Recommendations from '../blocks/recommendations';
import DefaultLayout from '../layout';

const BrandedSearchPageCollection = dynamic<BrandedSearchPageCollectionProps>(() =>
	import('@vakantiesnl/components/src/__LEGACY__/molecules/BrandedSearchPageCollection').then(
		(mod) => mod.BrandedSearchPageCollection,
	),
);

type ErrorProps = {
	errorCode: number;
};

type InitialProps = GlobalPageProps & {
	data: TemplateHomepage;
};

type Props = InitialProps | ErrorProps;

const RenderLayout: FC<InitialProps> = ({
	data: { uspsTitle, usps, brandedsearchpages, brandedsearchpagesHeading, headerImage, searchBlock },
}) => {
	const [loaded, setLoaded] = useState(false);

	const clickTracking = useCallback((cardPosition: number, cardTitle: string): void => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/promotions').then(({ clickPromotion }) => {
				track(clickPromotion(cardPosition, cardTitle));
			}),
		);
	}, []);

	const microCopies = useMicroCopyContext();

	useEffect(() => {
		if (!loaded) {
			import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
				import('@vakantiesnl/services/src/gtm/promotions').then(({ loadedPromotions }) => {
					track(loadedPromotions('homepage/promotions', brandedsearchpages));
				}),
			);
			setLoaded(true);
		}
	}, [loaded, brandedsearchpages]);

	/** Fire page_view event on load of the page */
	useEffect(() => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/homePage').then(({ homePageView }) => {
				track(homePageView());
			}),
		);
	}, []);

	return (
		<>
			<QuickSearch data={searchBlock} headerImage={headerImage?.[0] ?? null} />
			<Content>
				<UspExpandedCollection title={uspsTitle} usps={usps} />
				<RecommendationsProvider>
					<Recommendations heading={microCopies['recommendations.heading']} />
				</RecommendationsProvider>
				<Heading textAlign="center" headingLevel="h2">
					{brandedsearchpagesHeading}
				</Heading>
				{brandedsearchpages.map((props, key) => (
					<BrandedSearchPageCollection
						title={props.title}
						linkCards={props.brandedsearchpages}
						clickTracking={clickTracking}
						rowId={key}
						key={key}
						microCopies={microCopies}
					/>
				))}
			</Content>
		</>
	);
};

const MemoRenderLayout = memo(RenderLayout);

const HomePage: VaknlNextPage<Props> = (props) => <MemoRenderLayout {...(props as InitialProps)} />;

HomePage.getInitialProps = async (ctx: NextPreviewPageContext): Promise<Props> => {
	if (ctx.req && ctx.res) {
		const redirect = checkHomepageRedirect(ctx.req);
		if (redirect) {
			ctx.res?.writeHead(308, {
				Location: redirect,
			});

			ctx.res?.end();
			return {
				errorCode: -1,
			};
		}
	}

	let data: undefined | TemplateHomepage = await checkCache<TemplateHomepage>(
		`contentful:template-homepage:${ctx.locale}`,
	);
	if (!data) {
		const raw = await getEntryCollection<RawTemplateHomepage>('template-homepage', {
			include: 3,
		});

		raw.errors?.forEach((err) => errorHandler.report(`Error fetching homepage data: ${err}`));

		data = mapHomepageTemplate(raw);

		data?.id && (await storeInCache(`contentful:template-homepage:${ctx.locale}`, data));
	}

	return {
		data,
		seo: data.seo,
		contentfulId: data.id,
	};
};

HomePage.Layout = DefaultLayout;

export default HomePage;
